import { Line, mixins } from 'vue-chartjs';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
 
export default {
    extends: Line,
    mixins: [mixins.reactiveProp],
    // props: ["chartData", "options"],
    props: {
        chartData: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    mounted () {
        // this.addPlugin(ChartDataLabels);
        this.renderChart(
            this.chartData,
            this.options
        );
    }
}